<template>
    <div class="warehouse public_bg">
        <Top  name="仓库" email back v-if="topShow"></Top>
        <van-loading v-if="isLoading" color="#1989fa" />
        <div v-else class="public_tw">
            <div class="public_sheet">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">仓库</div>
                        <ul>
                            <van-skeleton title :row="3"  :loading="loading" />
                            <li v-for="(item, index) in list" :key="index"><span v-html="item.goods_name"></span></li>
                            <van-empty description="空" v-if="list.length==0&&!loading"/>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top'
export default {
    name:"wareHouse",
    data(){
        return {
            isLoading:true,
            params: {},
            list:[],
            loading: true,
            topShow: true
        }
    },
    components: {
      Top
    },
    created() {
        //获取路由参数
        this.params = this.$route.query;
        //有tag参数是app访问，需去掉头部
        if (this.params.tag) {
            this.topShow = false;
        }else{
            this.topShow = true;
        }
        //获取仓库数据
        this.getEquipmentData();
    },
    methods: {
        //获取仓库数据
        getEquipmentData() {
            this.$api.shopping.equipment({
                goods_id: this.params.goods_id,
                type: this.params.type
            }).then(res => {
                this.isLoading = false
                if (res.code == 200) {
                    this.loading = false;
                    // console.log(res)
                    this.list = res.data;

                }
            }).catch(err => {
                this.isLoading = false
                console.log(err);
            });
        },
    }
    
}
</script>
<style scoped>
@import "../../assets/css/common.css"
</style>